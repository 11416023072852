<template>
  <div class="worship-honor-deeds">
    <div class="swiper-container-header">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="index in 5"><img src="../../../assets/images/hall/worship/honor-deeds-item-icon.png" /></div>
      </div>
    </div>
    <div class="swiper-container-honor">
      <div class="swiper-wrapper">
        <div class="swiper-slide shadow" v-for="index in 5">
          <div class="slide-box">
            <div class="box-title">1984.10.24</div>
            <div class="box-content">
              荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长荣升歼灭战机战队队长
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="birth-death">
      <div class="date-of-birth text-center">
        <img src="../../../assets/images/hall/worship/honor-deeds-leficon.png">
        <div class="date">1386.2.21</div>
      </div>
      <div class="date-of-death text-center">
        <img src="../../../assets/images/hall/worship/honor-deeds-rig-icon.png">
        <div class="date">1386.2.21</div>
      </div>
    </div>
    <div class="deeds-comments">
      <div class="comments-dialog"><i class="sea-iconfont">&#xe66d;</i></div>
      <button type="button">添加</button>
    </div>
  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import Swiper from 'swiper/dist/js/swiper.js'

  export default {
    name: 'worship-honor-deeds',
    mounted() {
      const that = this;
      that.certifySwiper = new Swiper('.swiper-container-honor' ,{
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: false,
        loopedSlides: 5,
        initialSlide: 2,
        autoplay: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          progress: function(progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              var modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              var translate = slideProgress * modify * 50 + 'px';
              var scale = 1 - Math.abs(slideProgress) / 5;
              var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform('translateX(' + translate + ') scale(' + scale + ')');
              slide.css('zIndex', zIndex);
              slide.css('opacity', 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0);
              }
            }
          },
          setTransition: function(transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              slide.transition(transition);
            }

          }
        }
      })

      that.certifyHeader = new Swiper('.swiper-container-header' ,{
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: false,
        loopedSlides: 5,
        initialSlide: 2,
        autoplay: false,
        on: {
          progress: function(progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              var modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              var translate = slideProgress * modify * - 10 + 'px';
              var scale = 1 - Math.abs(slideProgress) / 5;
              var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform('translateX(' + translate + ') scale(' + scale + ')');
              slide.css('zIndex', zIndex);
              if (Math.abs(slideProgress) === 0) {
                slide.css('opacity', 1);
              } else {
                slide.css('opacity', 0.7);
              }
            }
          },
          setTransition: function(transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              slide.transition(transition);
            }
          }
        },
      })
      that.certifyHeader.controller.control = that.certifySwiper;
      that.certifySwiper.controller.control = that.certifyHeader;
    }
  }
</script>

<style lang="scss" scoped>
.worship-honor-deeds {
  position: relative;
  width: 100%;
  z-index: 2;
  height: 712px;
  background: url("../../../assets/images/hall/worship/honor-deeds-bg.png") no-repeat #ffffff;
  background-size: 100% 647px;
  .deeds-comments {
    @include w-flex;
    @include align-items;
    margin-top: 30px;
    padding: 0 20px;
    .comments-dialog {
      flex: 1;
      height: 50px;
      background: #E6E6E6;
      border: 1px solid #595959;
      box-shadow: 0px 5px 5px 0px rgba(96, 96, 96, 0.3);
      opacity: 0.5;
      border-radius: 25px;
      @include w-flex;
      @include align-items;
      @include justify-content;
      i {
        font-size: 32px;
        color: #595959;
      }
    }
    button {
      width: 150px;
      height: 50px;
      background: linear-gradient(-90deg, #79AAD9 0%, #407DB2 100%);
      box-shadow: -2px 2px 5px 0px rgba(101, 101, 100, 0.5);
      border-radius: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      border: none;
      margin-left: 30px;
    }
  }
  .birth-death {
    @include w-flex;
    @include align-items;
    justify-content: space-between;
    padding: 0 51px;
    .date {
      font-size: 18px;
      font-weight: bold;
      color: #4F3A35;
      margin-top: 6px;
    }
  }
  .swiper-container-header {
    overflow-x: hidden;
    -ms-overflow-x: hidden;
    padding: 30px 50px 0;
    .swiper-slide {
      width: 168px;
      height: 157px;
      img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }
    }
  }
  .swiper-container-honor {
    overflow-x: hidden;
    -ms-overflow-x: hidden;
    padding: 0 50px;
    .swiper-slide {
      width: 245px;
      height: 341px;
      @include w-flex;
      @include align-items;
      background: url("../../../assets/images/hall/worship/honor-deeds-item-bg.png") no-repeat #ffffff;
      background-size: cover;
      .slide-box {
        width: 100%;
        height: 245px;
        padding: 10px 20px;
        .box-title {
          font-size: 18px;
          font-weight: bold;
          color: #2A2A2A;
          text-align: center;
          margin-top: 16px;
        }
        .box-content {
          height: 172px;
          margin-top: 40px;
          text-align: center;
          scrollbar-width: none; /* firefox */
          -ms-overflow-style: none; /* IE 10+ */
          overflow-x: hidden;
          overflow-y: auto;
          &::-webkit-scrollbar {
             display: none; /* Chrome Safari */
          }
        }
      }
    }
  }
}
</style>
