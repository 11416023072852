import { render, staticRenderFns } from "./worship-honor-deeds.vue?vue&type=template&id=63b475b2&scoped=true&"
import script from "./worship-honor-deeds.vue?vue&type=script&lang=js&"
export * from "./worship-honor-deeds.vue?vue&type=script&lang=js&"
import style0 from "./worship-honor-deeds.vue?vue&type=style&index=0&id=63b475b2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b475b2",
  null
  
)

export default component.exports